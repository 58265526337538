"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Grid {
    constructor(size) {
        this.cells = [];
        this.getAvailableCell = () => {
            const cells = this.availableCells();
            if (!cells.length) {
                return;
            }
            return cells[Math.floor(Math.random() * cells.length)];
        };
        this.eachCell = (callback) => {
            for (let x = 0; x < this.size; x++) {
                for (let y = 0; y < this.size; y++) {
                    callback(x, y, this.cells[x][y]);
                }
            }
        };
        this.clone = () => {
            const grid = new Grid(this.size);
            this.eachCell((x, y, tile) => {
                if (!grid.cells[x])
                    grid.cells[x] = new Array([]);
                grid.cells[x][y] = tile ? tile.clone() : tile;
            });
            return grid;
        };
        this.dump = () => {
            const cells = new Array(this.cells.length);
            this.eachCell((x, y, tile) => {
                if (!cells[x])
                    cells[x] = new Array([]);
                cells[x][y] = tile ? tile.dump() : tile;
            });
            return {
                size: this.size,
                cells: cells
            };
        };
        this.hasCellsAvailable = () => !!this.availableCells().length;
        this.isCellAvailable = (cell) => !this.isCellOccupied(cell);
        this.getCellContent = (cell) => (this.isInBounds(cell) ? this.cells[cell.x][cell.y] : null);
        this.insertTile = (tile) => {
            this.cells[tile.x][tile.y] = tile;
        };
        this.removeTile = (tile) => {
            this.cells[tile.x][tile.y] = null;
        };
        this.isInBounds = (position) => {
            return position.x >= 0 && position.x < this.size && position.y >= 0 && position.y < this.size;
        };
        this.build = () => {
            for (let x = 0; x < this.size; x++) {
                const row = (this.cells[x] = []);
                for (let y = 0; y < this.size; y++) {
                    row.push(null);
                }
            }
        };
        this.availableCells = () => {
            const cells = [];
            this.eachCell((x, y, tile) => {
                if (!tile) {
                    cells.push({ x, y });
                }
            });
            return cells;
        };
        this.isCellOccupied = (cell) => !!this.getCellContent(cell);
        this.size = size;
        this.build();
    }
}
exports.default = Grid;
