"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class DOMUpdater {
    constructor() {
        this.tileBackgroundClass = '';
        this.update = (grid, metadata) => {
            this.tileBackgroundClass = metadata.tileBackgroundIndex != undefined ? `tile-option-${metadata.tileBackgroundIndex}` : '';
            window.requestAnimationFrame(() => {
                this.clearContainer(this.tileContainer);
                grid.cells.forEach((column) => {
                    column.forEach((cell) => {
                        if (cell) {
                            this.addTile(cell);
                        }
                    });
                });
                this.updateScore(metadata.score);
                if (metadata.isOver) {
                    this.getMessage(false);
                }
                if (metadata.isWin) {
                    this.getMessage(true);
                }
            });
        };
        this.restart = () => {
            this.clearMessage();
        };
        this.clearContainer = (container) => {
            if (container)
                while (container.firstChild) {
                    container.removeChild(container.firstChild);
                }
        };
        this.addTile = (tile) => {
            var _a;
            const element = document.createElement('div');
            const position = tile.prevPosition || { x: tile.x, y: tile.y };
            const getPositionClass = this.getPositionClass(position);
            const classes = ['tile', 'tile-' + tile.value, getPositionClass];
            if (this.tileBackgroundClass) {
                classes.push(this.tileBackgroundClass);
                classes.push(`${this.tileBackgroundClass}-${tile.value}`);
            }
            this.setClass(element, classes);
            element.textContent = tile.value.toString();
            if (tile.prevPosition) {
                window.requestAnimationFrame(() => {
                    classes[2] = this.getPositionClass({ x: tile.x, y: tile.y });
                    this.setClass(element, classes);
                });
            }
            else if (tile.mergedFrom) {
                classes.push('tile_merged');
                this.setClass(element, classes);
                tile.mergedFrom.forEach((merged) => {
                    this.addTile(merged);
                });
            }
            else {
                classes.push('tile_new');
                this.setClass(element, classes);
            }
            (_a = this.tileContainer) === null || _a === void 0 ? void 0 : _a.appendChild(element);
        };
        this.setClass = (element, classes) => {
            element.setAttribute('class', classes.join(' '));
        };
        this.normalizePosition = (position) => ({
            x: position.x + 1,
            y: position.y + 1
        });
        this.getPositionClass = (position) => {
            const { x, y } = this.normalizePosition(position);
            return `tile-position-${x}-${y}`;
        };
        this.updateScore = (score) => {
            const difference = score - this.score;
            this.score = score;
            this.clearContainer(this.scoreContainer);
            if (this.scoreContainer) {
                this.scoreContainer.textContent = this.score.toString();
                if (difference > 0) {
                    // const addition: Element = document.createElement('div');
                    // addition.classList.add('score-addition');
                    // addition.textContent = '+' + difference;
                    // this.scoreContainer.appendChild(addition);
                    this.updateBestScore();
                }
            }
        };
        this.getBestScore = () => {
            return parseInt(this.bestScoreContainer.textContent || '0');
        };
        this.updateBestScore = () => {
            const bestScore = localStorage.getItem(this.storageKey);
            const currentScore = this.score;
            if (!bestScore) {
                localStorage.setItem(this.storageKey, currentScore.toString());
            }
            if (bestScore && currentScore > parseInt(bestScore, 10)) {
                localStorage.setItem(this.storageKey, currentScore.toString());
            }
            if (this.bestScoreContainer)
                this.bestScoreContainer.textContent = localStorage.getItem(this.storageKey);
        };
        this.getMessage = (isWin) => {
            const type = isWin ? 'game-win' : 'game-over';
            const message = isWin ? 'You win!' : 'Game over!';
            if (this.messageContainer) {
                this.messageContainer.classList.add(type);
                this.messageContainer.querySelector('p').textContent = message;
            }
        };
        this.clearMessage = () => {
            if (this.messageContainer) {
                this.messageContainer.classList.remove('game-win', 'game-over');
            }
        };
        this.tileContainer = document.querySelector('.tiles');
        this.scoreContainer = document.querySelector('.current-score');
        this.messageContainer = document.querySelector('.message');
        this.bestScoreContainer = document.querySelector('.best-score');
        this.score = 0;
        this.storageKey = '2048-best-score';
        this.updateBestScore();
    }
}
exports.default = DOMUpdater;
