"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Tile {
    constructor(position, value) {
        this.save = () => {
            this.prevPosition = { x: this.x, y: this.y };
        };
        this.update = (position) => {
            this.x = position.x;
            this.y = position.y;
        };
        this.clone = () => {
            const position = {
                x: this.x, y: this.y
            };
            const tile = new Tile(position, this.value);
            tile.prevPosition = position;
            return tile;
        };
        this.x = position.x;
        this.y = position.y;
        this.value = value || 2;
        this.mergedFrom = null;
        this.prevPosition = null;
    }
    dump() {
        return {
            x: this.x,
            y: this.y,
            prevPosition: this.prevPosition ? { x: this.prevPosition.x, y: this.prevPosition.y } : null,
            mergedFrom: this.mergedFrom ? this.mergedFrom.map((merged) => merged.dump()) : null,
            value: this.value
        };
    }
}
exports.default = Tile;
